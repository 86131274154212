import React, { useState, useEffect, useRef, useMemo } from "react"
import { Typography } from "components/typography"
import ProgressBar from "components/survey/progress-bar"
import axios from "axios"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined"
import AttachMoneyIcon from "@mui/icons-material/AttachMoney"
import Grid from "@mui/material/Grid"
import { debounce } from "@mui/material/utils"
import * as styles from "./style.module.scss"
import { navigate } from "gatsby"
import { createTheme, Stack } from "@mui/material"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { useStore } from "react-redux"
import { apis as API } from "@sog/sdk"
import { DataCollector, DataCollectorAction } from "@sog/sdk"
import store from "state/store"
import { Select, MenuItem } from "@mui/material"
import * as Clickable from "components/clickable"

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return
  }

  const script = document.createElement("script")
  script.setAttribute("async", "")
  script.setAttribute("id", id)
  script.src = src
  position.appendChild(script)
}

const autocompleteService = { current: null }

interface MainTextMatchedSubstrings {
  offset: number
  length: number
}
interface StructuredFormatting {
  main_text: string
  secondary_text: string
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[]
}
interface PlaceType {
  description: string
  structured_formatting: StructuredFormatting
}

const UpdateYourProperty = props => {
  const store = useStore()

  const states = ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"]

  const currentIndex = props.location.state.currentIndex
  const [properties, setProperties] = useState(props.location.state.properties)
  const [propertyAddress, setPropertyAddress] = useState({})

  // geoapify api
  const autocompleteRef = useRef(null)
  const [inputPropertyAddress, setInputPropertyAddress] = useState("")
  const [autocompleteResults, setAutocompleteResults] = useState(null)

  const [latitude, setLatitude] = useState(store.latitude || null)
  const [longitude, setLongitude] = useState(store.longitude || null)
  const [coordinatesFetched, setCoordinatesFetched] = useState(store.coordinatesFetched || false)

  //  console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>")
  //  console.log(currentIndex)
  //  console.log(properties)
  //  console.log(propertyAddress)

  //  console.log(latitude)
  //  console.log(longitude)
  //  console.log(coordinatesFetched)

  const useStyles = () => ({
    inputField: {
      width: "100%",
      fontFamily: "Arboria-Bold, sans-serif",
      fontSize: "18px",
      color: "#202020",
      // backgroundColor: "rgba(112, 112, 112, 0.25)",
      height: "60px",
      paddingLeft: "30px",
      paddingRight: "10px",
    },
    manualInputField: {
      width: "100%",
      fontFamily: "Arboria-Book, sans-serif",
      fontSize: "18px",
      color: "#202020",
      // backgroundColor: "rgba(112, 112, 112, 0.25)",
      height: "60px",
      paddingRight: "10px",
    },
    dropdownField: {
      width: "30%",
      height: "56px",
      fontFamily: "Arboria-Book, sans-serif",
      fontSize: "18px",
      color: "#202020",
    },
  })
  const classes = useStyles()

  useEffect(() => {
    if (properties.length <= currentIndex) {
      const newProperties = [...properties]
      newProperties.push(generatePropertyObject("INVESTMENT"))
      setProperties(newProperties)
    }
  }, [])

  function generatePropertyObject(purpose: string) {
    return {
      address1: "",
      address2: "",
      addressApiResponse: null,
      addressEnteredManually: false,
      value: "",
      purpose: purpose,
      addressError: false,
      addressDuplicateError: false,
      valueError: false,
    }
  }

  const autoCompleteAddress = () => {
    if (properties[currentIndex].address1.length + properties[currentIndex].address2.length > 0) {
      const loadAutocomplete = async () => {
        // test api key
        const url = "https://api.geoapify.com/v1/geocode/autocomplete?text=" + properties[currentIndex].address1 + properties[currentIndex].address2 + "&filter=countrycode:au&format=json&apiKey=204e7ef6395b48359d2d31c5a930e1be&type=amenity" + (latitude !== null && longitude !== null ? `&bias=proximity:${longitude},${latitude}` : "&bias=countrycode:au")
        const response = await axios.get(url)
        setAutocompleteResults(response)
      }
      loadAutocomplete()
    }
  }

  useEffect(() => {
    if (properties[currentIndex]) {
      autoCompleteAddress()
    }
    // const timeOutId = setTimeout(() => autoCompleteAddress(), 500)
    // return () => clearTimeout(timeOutId)
  }, [inputPropertyAddress])

  const updatePropertyAddress = (address1: string, address2: string, index: number) => {
    setInputPropertyAddress(address1 + address2)
    const newProperties = properties.map((p, i) => {
      if (i === index) {
        return {
          ...p,
          address1: address1,
          address2: address2,
        }
      } else {
        return p
      }
    })
    setProperties(newProperties)
    // store.properties = newProperties
    // dataCollectorPropertiesUpdated(newProperties)
  }

  const updatePropertyValue = (value: string, index: number) => {
    const newProperties = properties.map((p, i) => {
      if (i === index) {
        return {
          ...p,
          value: value,
        }
      } else {
        return p
      }
    })
    setProperties(newProperties)
    // store.properties = newProperties
    // dataCollectorPropertiesUpdated(newProperties)
  }

  const updatePropertyPurpose = (value: string, index: number) => {
    const newProperties = properties.map((p, i) => {
      if (i === index) {
        return {
          ...p,
          purpose: value,
        }
      } else {
        return p
      }
    })
    setProperties(newProperties)
    // store.properties = newProperties
    // dataCollectorPropertiesUpdated(newProperties)
  }

  const updateAddressEnteredManually = (value: boolean, index: number) => {
    const newProperties = properties.map((p, i) => {
      if (i === index) {
        return {
          ...p,
          addressEnteredManually: value,
        }
      } else {
        return p
      }
    })
    setProperties(newProperties)
    // store.properties = newProperties
    // dataCollectorPropertiesUpdated(newProperties)

    if (!(index in propertyAddress)) {
      const newPropertyAddress = { ...propertyAddress }
      newPropertyAddress[index] = {
        "Unit Number": "",
        "Street Number": "",
        "Street Name": "",
        "Street Type": "",
        "Suburb": "",
        "State": "",
        "Postcode": "",
        "Unit Number Error": false,
        "Street Number Error": false,
        "Street Name Error": false,
        "Street Type Error": false,
        "Suburb Error": false,
        "State Error": false,
        "State Invalid Error": false,
        "Postcode Error": false,
        "Postcode Invalid Error": false,
      }
      setPropertyAddress(newPropertyAddress)
      // store.propertyAddress = newPropertyAddress
      // DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_PROPERTIES_UPDATED, { "surveyId": store.getState().survey.surveyId, "propertyAddress": newPropertyAddress })
    }
  }

  const autocompleteOnClick = (result: any) => {
    const address1 = result.address_line1
    const address2 = result.address_line2.replace(", Australia", "")
    autocompleteRef.value = address1 + " " + address2
    const newProperties = properties.map((p, i) => {
      if (i === currentIndex) {
        return {
          ...p,
          address1: address1,
          address2: address2,
          addressApiResponse: result,
        }
      } else {
        return p
      }
    })
    setProperties(newProperties)
    // store.properties = newProperties
    // dataCollectorPropertiesUpdated(newProperties)
    setInputPropertyAddress("")
    setAutocompleteResults(null)
  }

  const formatPropertyValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value.replace(/\D+/g, "")
    if (value === "") {
      e.target.value = ""
    } else {
      e.target.value = Number(value).toLocaleString()
    }
  }

  const updateManualPropertyAddress = (value: string, label: string, index: number) => {
    const newPropertyAddress = { ...propertyAddress }
    newPropertyAddress[index][label] = value
    setPropertyAddress(newPropertyAddress)
    // DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_PROPERTIES_UPDATED, { "surveyId": store.getState().survey.surveyId, "propertyAddress": newPropertyAddress })
  }

  const updatePostcode = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const value = e.target.value.replace(/\D+/g, "").slice(0, 4)
    if (value === "") {
      e.target.value = ""
    } else {
      e.target.value = value
    }
    updateManualPropertyAddress(value, "Postcode", index)
  }

  const addressField = (label: string, required: boolean, error: boolean, index: number) => {
    const isPostcode = label === "Postcode"
    return (
      <Stack direction="row" justifyContent="space-between" alignItems="center" style={{ height: "60px", backgroundColor: "#FFFFFF", paddingLeft: "10px", outline: error ? "3px solid #F9689C" : "none" }}>
        <Typography.P fontColor="#202020" style={{ width: "40%" }}>
          {label}
        </Typography.P>
        {label == "State" ? (
          <div className={styles.inputFieldContainer}>
            <Select
              defaultValue={location}
              variant="standard"
              disableUnderline
              className={styles.inputField}
              style={classes.dropdownField}
              // input={fontSize:2}
              MenuProps={{
                disableScrollLock: true,
              }}
              onChange={e => updateManualPropertyAddress(e.target.value, label, index)}
            >
              {states.map((o, i) => {
                return (
                  <MenuItem key={i} value={o} style={{ fontSize: "18px", fontFamily: "Arboria-Book, sans-serif" }}>
                    {o}
                  </MenuItem>
                )
              })}
            </Select>
          </div>
        ) : (
          <TextField
            variant="standard"
            autoComplete="off"
            placeholder={required ? "Required" : "Optional"}
            // inputRef={loanAmountRef}
            InputProps={{
              disableUnderline: true,
              style: classes.manualInputField,
            }}
            inputProps={{
              style: { textAlign: "right" },
              inputMode: isPostcode ? "decimal" : "text",
              pattern: isPostcode ? "d{4,4}" : "*",
            }}
            onChange={e => (isPostcode ? updatePostcode(e, index) : updateManualPropertyAddress(e.target.value, label, index))}
          />
        )}
      </Stack>
    )
  }

  const validateInputs = () => {
    let noError = true
    const length = 1
    const newProperties = structuredClone(properties)
    const uniqueAddresses = {}

    for (let i = 0; i < length; i++) {
      const p = newProperties[i]
      if (properties[currentIndex].addressEnteredManually && propertyAddress[currentIndex]) {
        // if (propertyAddress[currentIndex]["Street Number"].length === 0) {
        //   propertyAddress[currentIndex]["Street Number Error"] = true
        //   noError = false
        // } else {
        //   propertyAddress[currentIndex]["Street Number Error"] = false
        // }
        Object.keys(propertyAddress[currentIndex])
          .slice(1, 7)
          .map(k => {
            if (propertyAddress[currentIndex][k].length === 0) {
              propertyAddress[currentIndex][k + " Error"] = true
              noError = false
            } else {
              propertyAddress[currentIndex][k + " Error"] = false
            }
            if (k === "State" && propertyAddress[currentIndex][k].length > 0) {
              if (!states.includes(propertyAddress[currentIndex][k])) {
                propertyAddress[currentIndex][k + " Invalid Error"] = true
                noError = false
              } else {
                propertyAddress[currentIndex][k + " Invalid Error"] = false
              }
            } else if (k === "Postcode" && propertyAddress[currentIndex][k].length > 0) {
              if (propertyAddress[currentIndex][k].length !== 4) {
                propertyAddress[currentIndex][k + " Invalid Error"] = true
                noError = false
              } else {
                propertyAddress[currentIndex][k + " Invalid Error"] = false
              }
            }
          })
      } else {
        if (!properties[currentIndex].address1 || !properties[currentIndex].address2) {
          properties[currentIndex].address1 = ""
          properties[currentIndex].address2 = ""
          properties[currentIndex].addressError = true
          noError = false
        } else {
          properties[currentIndex].addressError = false
        }
      }

      const key = properties[currentIndex].address1 + properties[currentIndex].address2

      if (!(key in uniqueAddresses)) {
        uniqueAddresses[key] = i
        properties[currentIndex].addressDuplicateError = false
      } else {
        properties[currentIndex].addressDuplicateError = true
        noError = false
      }

      if ((!properties[currentIndex].value + "").replace(/\D/g, "")) {
        properties[currentIndex].valueError = true
        noError = false
      } else {
        properties[currentIndex].valueError = false
      }
    }

    setProperties(newProperties)
    // store.properties = newProperties
    return noError
  }

  const cleanInputs = () => {
    let jsonInput = properties
    jsonInput.map((p, i) => {
      if (properties[i].addressEnteredManually && propertyAddress[currentIndex]) {
        properties[i].propertyNumber = (propertyAddress[currentIndex]["Unit Number"] ? propertyAddress[currentIndex]["Unit Number"] + "/" : "") + propertyAddress[currentIndex]["Street Number"]
        properties[i].street = propertyAddress[currentIndex]["Street Name"] + " " + propertyAddress[currentIndex]["Street Type"]
        properties[i].country = "Australia"
        properties[i].postcode = propertyAddress[currentIndex]["Postcode"]
        properties[i].suburb = propertyAddress[currentIndex]["Suburb"]
        properties[i].lon = null
        properties[i].lat = null
        properties[i].state = propertyAddress[currentIndex]["State"]

        properties[i].address1 = properties[i].propertyNumber + " " + properties[i].street
        properties[i].address2 = properties[i].suburb + " " + properties[i].state + " " + properties[i].postcode
      } else {
        if (properties[i].addressApiResponse) {
          properties[i].propertyNumber = properties[i].addressApiResponse.housenumber
          properties[i].street = properties[i].addressApiResponse.street
          properties[i].country = properties[i].addressApiResponse.country
          properties[i].postcode = properties[i].addressApiResponse.postcode
          properties[i].suburb = properties[i].addressApiResponse.city
          properties[i].lon = properties[i].addressApiResponse.lon
          properties[i].lat = properties[i].addressApiResponse.lat
          properties[i].state = properties[i].addressApiResponse.state_code
        }
      }

      properties[i].value = Number((properties[i].value + "").replaceAll(",", ""))

      delete properties[i].addressApiResponse
      delete properties[i].addressError
      delete properties[i].addressDuplicateError
      delete properties[i].valueError
    })

    return jsonInput
  }

  if (!properties[currentIndex]) {
    return
  }

  return (
    <div className="page-container">
      <div className="survey-container">
        <Typography.H3 comp="hl-survey">Update your property</Typography.H3>
        <div style={{ backgroundColor: "#EEF4F5", padding: "15px 10px 20px", marginTop: "60px" }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" style={{ height: "60px" }}>
            <button onClick={() => updatePropertyPurpose("OWNER_OCCUPIED", currentIndex)} style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "49%", height: "100%", backgroundColor: properties[currentIndex].purpose === "OWNER_OCCUPIED" ? "#FFFFFF" : "#EEF4F5", border: "2px solid #FFFFFF", borderRadius: "50px", cursor: "pointer" }}>
              <Typography.P comp={properties[currentIndex].purpose === "OWNER_OCCUPIED" ? "hl-button-selected" : "hl-button"} fontColor="#202020">
                Home
              </Typography.P>
            </button>
            <button onClick={() => updatePropertyPurpose("INVESTMENT", currentIndex)} style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "49%", height: "100%", backgroundColor: properties[currentIndex].purpose === "INVESTMENT" ? "#FFFFFF" : "#EEF4F5", border: "2px solid #FFFFFF", borderRadius: "50px", cursor: "pointer" }}>
              <Typography.P comp={properties[currentIndex].purpose === "INVESTMENT" ? "hl-button-selected" : "hl-button"} fontColor="#202020">
                Investment
              </Typography.P>
            </button>
          </Stack>

          <Typography.P comp="hl-label" style={{ marginTop: "15px", marginBottom: "10px" }}>
            Address
          </Typography.P>
          {properties[currentIndex].addressEnteredManually && propertyAddress[currentIndex] ? (
            <div>
              {addressField("Unit Number", false, propertyAddress[currentIndex]["Unit Number Error"], currentIndex)}
              {addressField("Street Number", true, propertyAddress[currentIndex]["Street Number Error"], currentIndex)}
              {addressField("Street Name", true, propertyAddress[currentIndex]["Street Name Error"], currentIndex)}
              {addressField("Street Type", true, propertyAddress[currentIndex]["Street Type Error"], currentIndex)}
              {addressField("Suburb", true, propertyAddress[currentIndex]["Suburb Error"], currentIndex)}
              {addressField("State", true, propertyAddress[currentIndex]["State Error"] || propertyAddress[currentIndex]["State Invalid Error"], currentIndex)}
              {propertyAddress[currentIndex]["State Invalid Error"] ? (
                <Typography.P comp="small-bold" fontColor="#F9689C">
                  {states.slice(0, states.length - 2).join(", ") + " or " + states[states.length - 1]}
                </Typography.P>
              ) : null}
              {addressField("Postcode", true, propertyAddress[currentIndex]["Postcode Error"] || propertyAddress[currentIndex]["Postcode Invalid Error"], currentIndex)}
              {propertyAddress[currentIndex]["Postcode Invalid Error"] ? (
                <Typography.P comp="small-bold" fontColor="#F9689C">
                  {"Enter a 4 digit postcode"}
                </Typography.P>
              ) : null}
              <Stack justifyContent="center" alignItems="center" style={{ height: "60px", borderTop: "1px solid #EEF4F5", cursor: "pointer", backgroundColor: "#FFFFFF" }} onClick={() => updateAddressEnteredManually(false, i)}>
                <Typography.P comp="medium-bold" fontColor="#4D4D4D">
                  Use address search
                </Typography.P>
              </Stack>
            </div>
          ) : (
            <div>
              <div style={{ position: "relative", backgroundColor: "#FFFFFF" }}>
                <img src={"/images/location_on_FILL0_wght400_GRAD0_opsz48.svg"} style={{ position: "absolute", top: "20px", left: "5px", height: "20px" }} />
                <div className={styles.linearOpacityDiv} />
                <TextField
                  key={currentIndex}
                  style={{ width: "100%", outline: properties[currentIndex].addressError || properties[currentIndex].addressDuplicateError ? "3px solid #F9689C" : "none" }}
                  variant="standard"
                  autoComplete="off"
                  value={properties[currentIndex].address1 + (properties[currentIndex].address2 ? ", " + properties[currentIndex].address2 : "")}
                  // inputRef={loanAmountRef}
                  InputProps={{
                    disableUnderline: true,
                    style: classes.inputField,
                    // className: styles.inputField,
                  }}
                  onChange={e => updatePropertyAddress(e.target.value, "", currentIndex)}
                />
              </div>
              {properties[currentIndex].addressDuplicateError ? (
                <Typography.P comp="small-bold" fontColor="#F9689C">
                  Duplicate address
                </Typography.P>
              ) : null}
              {inputPropertyAddress.length > 0 ? (
                <div style={{ borderTop: "1px solid #EEF4F5", backgroundColor: "#FFFFFF" }}>
                  {autocompleteResults &&
                    autocompleteResults.data.results.map((result, i) => {
                      return (
                        <Stack key={i} className={styles.autocomplete} justifyContent="center" alignItems="center" onClick={() => autocompleteOnClick(result)}>
                          <Typography.P comp="medium-tight" fontColor="#797979" style={{ width: "100%" }}>
                            {result.address_line1}
                          </Typography.P>
                          <Typography.P comp="medium-tight" fontColor="#797979" style={{ width: "100%" }}>
                            {result.address_line2.replace(", Australia", "")}
                          </Typography.P>
                        </Stack>
                      )
                    })}
                  <Stack justifyContent="center" alignItems="center" style={{ height: "60px", borderTop: "1px solid #EEF4F5", cursor: "pointer", marginTop: autocompleteResults && autocompleteResults.data.results.length > 0 ? "20px" : "0px" }} onClick={() => updateAddressEnteredManually(true, currentIndex)}>
                    <Typography.P comp="hl-medium" fontColor="#4D4D4D">
                      Can't find? Enter manually
                    </Typography.P>
                  </Stack>
                </div>
              ) : null}
            </div>
          )}

          <Typography.P comp="hl-label" style={{ marginTop: "15px", marginBottom: "10px" }}>
            Estimated property value
          </Typography.P>
          <div style={{ position: "relative", backgroundColor: "#FFFFFF" }}>
            <Typography.P comp="medium-bold" style={{ position: "absolute", top: "15px", left: "10px" }}>
              $
            </Typography.P>
            <div className={styles.linearOpacityDiv} />
            <TextField
              style={{ width: "100%", outline: properties[currentIndex].valueError ? "3px solid #F9689C" : "none" }}
              defaultValue={properties[currentIndex].value.toLocaleString()}
              variant="standard"
              autoComplete="off"
              // inputRef={loanAmountRef}
              InputProps={{
                disableUnderline: true,
                style: classes.inputField,
                // className: styles.inputField,
              }}
              inputProps={{
                inputMode: "decimal",
                pattern: "[0-9]*",
              }}
              onKeyDown={e => {
                if (e.key === "Enter" || e.key === "Escape") {
                  (e.target as EventTarget & HTMLDivElement).blur()
                }
              }}
              onFocus={e => setTimeout(() => {
                const l = e.target.value.length
                const start = e.target.selectionStart || 0
                const end = Math.max(start, e.target.selectionEnd || 0)
                const v = Number(e.target.value.replace(/\D+/g, ''))
                e.target.value = v === 0 ? "": v.toString()
                e.target.selectionStart = Math.max(0, start - l + e.target.value.length)
                e.target.selectionEnd = Math.max(0, end - l + e.target.value.length)
              }, 0)}
              onChange={(e) => {
                const l = e.target.value.length
                const start = e.target.selectionStart || 0
                const end = Math.max(start, e.target.selectionEnd || 0)
                const v = Number(e.target.value.replace(/\D+/g, ''))
                e.target.value = v === 0 ? "": v.toString()
                e.target.selectionStart = Math.max(0, start - l + e.target.value.length)
                e.target.selectionEnd = Math.max(0, end - l + e.target.value.length)
              }}
              onBlur={e => {
                formatPropertyValue(e)
                updatePropertyValue(e.target.value, currentIndex)
              }}
            />
          </div>
        </div>
        <Clickable.Text
          comp={3}
          className="hl-survey-button"
          style={{ marginTop: "60px" }}
          onClick={() => {
            if (validateInputs()) {
              const jsonInput = cleanInputs()
              properties[currentIndex].timestamp = Math.floor(Date.now() / 1000)
              if (properties[currentIndex].id == undefined) {
                properties[currentIndex].id = currentIndex + 1
                properties[currentIndex].loans = []
                navigate("/user/update-your-provider", {
                  state: {
                    properties: properties,
                    currentIndex: currentIndex,
                  },
                })
              } else {
                // DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_PROPERTIES_SUBMITTED, { "surveyId": store.getState().survey.surveyId, ...jsonInput })
                API.updateUserProducts({ properties: properties, updateMode: "OVERWRITE" }, (response: any) => {
                  if (response) {
                    navigate("/user/dashboard")
                  }
                })
              }
            }
          }}
        >
          {currentIndex + 1 == properties.length ? "Continue" : "Update"}
        </Clickable.Text>
        <Clickable.Text
          comp={3}
          secondary
          className="hl-survey-button"
          style={{ marginTop: "20px" }}
          onClick={() => {
            navigate("/user/dashboard")
          }}
        >
          Cancel
        </Clickable.Text>
      </div>
    </div>
  )
}

export default UpdateYourProperty
