import React, { useState, useEffect, useRef, useMemo } from "react"
import { Typography } from "components/typography"
import ProgressBar from "components/survey/progress-bar"
import axios from "axios"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined"
import AttachMoneyIcon from "@mui/icons-material/AttachMoney"
import Grid from "@mui/material/Grid"
import { debounce } from "@mui/material/utils"
import * as styles from "./style.module.scss"
import { navigate } from "gatsby"
import { createTheme, Stack } from "@mui/material"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { useStore } from "react-redux"
import { apis as API } from "@sog/sdk"
import { DataCollector, DataCollectorAction } from "@sog/sdk"
import store from "state/store"
import { Select, MenuItem } from "@mui/material"
import HelpDialog from "components/help-dialog"
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { providerImage } from "components/survey/utils"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs, { Dayjs } from "dayjs"
import * as Clickable from "components/clickable"

const useStyles = () => ({
  inputField: {
    width: "100%",
    height: "60px",
    fontFamily: "Arboria-Bold, sans-serif",
    fontSize: "20px",
    color: "#202020",
    paddingLeft: "30px",
    paddingRight: "10px",
  },
  rateInputField: {
    width: "100%",
    height: "60px",
    fontFamily: "Arboria-Bold, sans-serif",
    fontSize: "20px",
    color: "#202020",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  dateInputField: {
    width: "100%",
    height: "60px",
    fontFamily: "Arboria-Bold, sans-serif",
    fontSize: "20px",
    color: "#202020",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
})

const UpdateYourHomeLoan = props => {
  const classes = useStyles()

  const currentIndex = props.location.state.currentIndex
  const properties = props.location.state.properties
  const provider = props.location.state.provider

  const [rateLowerLimit, setRateLowerLimit] = useState(1)
  const [rateUpperLimit, setRateUpperLimit] = useState(15)

  const [rbaRates, setRbaRates] = useState({})
  const [splitLoan, setSplitLoan] = useState({})
  const [offsetAccounts, setOffsetAccounts] = useState({})
  const [loans, setLoans] = useState([])
  const today = new Date()

  const generateLoanObject = () => {
    return {
      value: "",
      repaymentType: "PRINCIPAL_AND_INTEREST",
      rateType: "VARIABLE",
      rate: null,
      calendarIsOpen: false,
      calendarYear: today.getFullYear(),
      expiryDate: null,
      knowRevertRate: true,
      knowRevertRateErrorRevertTo: null,
      revertRate: null,
      valueError: false,
      totalValueError: false,
      rateError: false,
      expiryDateError: false,
      revertRateError: false,
    }
  }

  useEffect(() => {
    const newLoans = properties[currentIndex].loans
    newLoans.map((l, i) => {
      l.value = Number(l.value).toLocaleString()
      l.rate = (l.rate * 100).toFixed(2)
      l.calendarYear = today.getFullYear()
      if (l.expiryDate) {
        l.expiryDate = dayjs(l.expiryDate, "YYYY-MM-DD")
      }
      if (l.revertRate) {
        l.revertRate = (l.revertRate * 100).toFixed(2)
      }
      l.knowRevertRateErrorRevertTo = null
      l.valueError = false
      l.totalValueError = false
      l.rateError = false
      l.expiryDateError = false
      l.revertRateError = false
    })

    const newSplitLoan = { ...splitLoan }
    const newOffsetAccounts = { ...offsetAccounts }
    properties.map((p, i) => {
      newSplitLoan[i] = p.loans.length > 1
      newOffsetAccounts[i] = p.offset
    })

    if (newLoans.length <= 1) {
      newLoans.push(generateLoanObject())
    }

    setLoans(newLoans)
    setSplitLoan(newSplitLoan)

    API.getHLParameter((responseJson: any) => {
      if (responseJson) {
        const rbaRates = { OWNER_OCCUPIED: responseJson["ownerOccupied"], INVESTMENT: responseJson["investment"] }
        setRbaRates(rbaRates)

        setRateLowerLimit(responseJson["rateLowerLimit"])
        setRateUpperLimit(responseJson["rateUpperLimit"])
      }
    })
  }, [])

  const months = [
    {
      "1": "Jan",
      "2": "Feb",
      "3": "Mar",
    },
    {
      "4": "Apr",
      "5": "May",
      "6": "Jun",
    },
    {
      "7": "Jul",
      "8": "Aug",
      "9": "Sep",
    },
    {
      "10": "Oct",
      "11": "Nov",
      "12": "Dec",
    },
  ]

  const updateSplitLoan = (value: boolean, index: number) => {
    const newSplitLoan = { ...splitLoan }
    newSplitLoan[index] = value
    setSplitLoan(newSplitLoan)
    // DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_LOAN_UPDATED, { "surveyId": state.survey.surveyId, "splitLoan": newSplitLoan })
  }

  const formatValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value.replace(/\D+/g, "")
    if (value === "") {
      e.target.value = ""
    } else {
      e.target.value = Number(value).toLocaleString()
    }
  }

  const updateLoanValue = (value: string, index: number, i: number) => {
    const newLoans = [...loans]

    newLoans[i].value = value
    setLoans(newLoans)
    // DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_LOAN_UPDATED, { "surveyId": state.survey.surveyId, "loans": newLoans })
  }

  const updateRepaymentType = (value: string, index: number, i: number) => {
    const newLoans = [...loans]
    newLoans[i].repaymentType = value
    setLoans(newLoans)
    // DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_LOAN_UPDATED, { "surveyId": state.survey.surveyId, "loans": newLoans })
  }

  const setRateValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const vArr = e.target.value.replace(/[^\d\.]/g, "").split(".")
    const v = vArr.length === 1 ? vArr[0] : vArr[0] + "." + vArr[1]
    const l = e.target.value.length
    const start = e.target.selectionStart || 0
    const end = Math.max(start, e.target.selectionEnd || 0)
    e.target.value = v
    e.target.selectionStart = Math.max(0, start - l + v.length)
    e.target.selectionEnd = Math.max(0, end - l + v.length)
  }

  const formatRateValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    // if (e.target.value.replace(/[^\d.]/g, '') == "") {
    //   e.target.value = ""
    //   return
    // }

    let value = e.target.value.replace(/\./g, (val, index, str) => (index === str.indexOf(".") ? val : ""))

    value = value.replace(/[^\d.]/g, "")
    e.target.value = value
  }

  const updateRateType = (value: string, index: number, i: number) => {
    const newLoans = [...loans]
    newLoans[i].rateType = value
    setLoans(newLoans)
    // DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_LOAN_UPDATED, { "surveyId": state.survey.surveyId, "loans": newLoans })
  }

  const updateRateValue = (e: FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>, index: number, i: number) => {
    let rateValue = Number(e.target.value)
    const newLoans = [...loans]
    newLoans[i].rate = rateValue.toFixed(2)
    e.target.value = rateValue.toFixed(2)
    setLoans(newLoans)
    // DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_LOAN_UPDATED, { "surveyId": state.survey.surveyId, "loans": newLoans })
  }

  const updateExpiryDate = (value: Dayjs, index: number, i: number) => {
    if (value["$y"] < today.getFullYear()) {
      value["$y"] = today.getFullYear()
    }
    if (value["$y"] == today.getFullYear() && value["$M"] < today.getMonth()) {
      value["$M"] = today.getMonth()
    }
    const newLoans = [...loans]
    newLoans[i].expiryDate = value
    setLoans(newLoans)
    // DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_LOAN_UPDATED, { "surveyId": state.survey.surveyId, "loans": newLoans })
  }

  const updateRevertRateValue = (e: FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>, index: number, i: number) => {
    let rateValue = Number(e.target.value)
    const newLoans = [...loans]
    newLoans[i].revertRate = rateValue.toFixed(2)
    e.target.value = rateValue.toFixed(2)
    setLoans(newLoans)
    // DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_LOAN_UPDATED, { "surveyId": state.survey.surveyId, "loans": newLoans })
  }

  const updateKnowRevertRate = (value: boolean, index: number, i: number) => {
    const newLoans = [...loans]
    newLoans[i].knowRevertRate = value
    setLoans(newLoans)
    // DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_LOAN_UPDATED, { "surveyId": state.survey.surveyId, "loans": newLoans })
  }

  const updateOffsetAccounts = (value: string, error: boolean, index: number) => {
    const newOffsetAccounts = { ...offsetAccounts }
    newOffsetAccounts[index] = { value: value, error: error }
    setOffsetAccounts(newOffsetAccounts)
    const dataCollectorOffsetAccounts = {}
    Object.entries(newOffsetAccounts).map(([k, v], i) => {
      dataCollectorOffsetAccounts["P" + (Number(k) + 1)] = v.value
    })
    // DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_OFFSET_UPDATED, { "surveyId": state.survey.surveyId, ...dataCollectorOffsetAccounts })
  }

  const updateCalendarIsOpen = (value: boolean, index: number, i: number) => {
    const newLoans = [...loans]
    newLoans[i].calendarIsOpen = value
    setLoans(newLoans)
    // DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_LOAN_UPDATED, { "surveyId": state.survey.surveyId, "loans": newLoans })
  }

  const updateExpiryDateFromCalendar = (month: string, index: number, i: number) => {
    const dateObj = dayjs(loans[i].calendarYear + "-" + month)
    updateExpiryDate(dateObj, index, i)
    updateCalendarIsOpen(false, index, i)
  }

  const updateYear = (year: number, index: Number, i: number) => {
    const newLoans = [...loans]
    newLoans[i].calendarYear = year
    if (newLoans[i].expiryDate && year == today.getFullYear() && newLoans[i].expiryDate["$M"] < today.getMonth()) {
      newLoans[i].expiryDate["$M"] = today.getMonth()
    }
    setLoans(newLoans)
    // DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_LOAN_UPDATED, { "surveyId": state.survey.surveyId, "loans": newLoans })
  }

  const removeLoan = (index: number, i: number) => {
    const newLoans = [...loans]
    newLoans.splice(i, 1)
    setLoans(newLoans)
    // DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_LOAN_UPDATED, { "surveyId": state.survey.surveyId, "loans": newLoans })
  }

  const removeOffsetAccount = (index: number) => {
    const newOffsetAccounts = { ...offsetAccounts }
    delete newOffsetAccounts[index]
    setOffsetAccounts(newOffsetAccounts)
    const dataCollectorOffsetAccounts = {}
    newOffsetAccounts.map((o, i) => {
      dataCollectorOffsetAccounts["P" + (i + 1)] = o
    })
    // DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_OFFSET_UPDATED, { "surveyId": state.survey.surveyId, ...dataCollectorOffsetAccounts })
  }

  const validateInputs = () => {
    let noError = true
    const newLoans = structuredClone(loans)
    const newOffsetAccounts = { ...offsetAccounts }

    const length = splitLoan[currentIndex] ? newLoans.length : 1
    const propertyValue = properties[currentIndex].value
    let totalPropertyLoanValue = 0

    for (let j = 0; j < length; j++) {
      if (!newLoans[j].value || cleanValue(newLoans[j].value) == 0) {
        newLoans[j].valueError = true
        noError = false
      } else {
        newLoans[j].valueError = false
        totalPropertyLoanValue += cleanValue(newLoans[j].value)
      }
      if (!newLoans[j].rate || newLoans[j].rate < rateLowerLimit || newLoans[j].rate > rateUpperLimit) {
        newLoans[j].rateError = true
        noError = false
      } else {
        newLoans[j].rateError = false
      }
      if (newLoans[j].rateType == "FIXED" && !newLoans[j].expiryDate) {
        newLoans[j].expirydateError = true
        noError = false
      } else {
        newLoans[j].expirydateError = false
      }
      if (newLoans[j].rateType == "FIXED" && newLoans[j].knowRevertRate && (!newLoans[j].revertRate || newLoans[j].revertRate < rateLowerLimit || newLoans[j].revertRate > rateUpperLimit)) {
        newLoans[j].revertRateError = true
        noError = false
      } else {
        newLoans[j].revertRateError = false
      }
    }

    // find best variable rate within property for each repayment type
    const bestVariableRate: any = {}
    for (let j = 0; j < length; j++) {
      if (newLoans[j].rateType == "VARIABLE") {
        if (!(newLoans[j].repaymentType in bestVariableRate)) {
          bestVariableRate[newLoans[j].repaymentType] = { rate: null, loanIndex: null }
        }
        if (!bestVariableRate[newLoans[j].repaymentType].rate || newLoans[j].rate < bestVariableRate[newLoans[j].repaymentType].rate) {
          bestVariableRate[newLoans[j].repaymentType].rate = newLoans[j].rate
          bestVariableRate[newLoans[j].repaymentType].loanIndex = j
        }
      }
    }

    for (let j = 0; j < length; j++) {
      if (newLoans[j].rateType == "FIXED" && !newLoans[j].knowRevertRate && newLoans[j].repaymentType in bestVariableRate) {
        newLoans[j].knowRevertRate = true
        newLoans[j].knowRevertRateErrorRevertTo = bestVariableRate[newLoans[j].repaymentType]
        noError = false
      } else {
        newLoans[j].knowRevertRateErrorRevertTo = null
      }
    }

    if (totalPropertyLoanValue > propertyValue) {
      for (let j = 0; j < length; j++) {
        newLoans[j].totalValueError = true
      }
      noError = false
    } else {
      for (let j = 0; j < length; j++) {
        newLoans[j].totalValueError = false
      }
    }

    if (currentIndex in newOffsetAccounts) {
      if (newOffsetAccounts[currentIndex].value.length == 0) {
        newOffsetAccounts[currentIndex].error = true
        noError = false
      } else if (cleanValue(newOffsetAccounts[currentIndex].value) > totalPropertyLoanValue) {
        newOffsetAccounts[currentIndex].error = true
        noError = false
      } else {
        newOffsetAccounts[currentIndex].error = false
      }
    }
    setLoans(newLoans)
    setOffsetAccounts(newOffsetAccounts)

    return noError
  }

  const cleanValue = (value: string) => {
    return Number(value.replaceAll(",", ""))
  }

  const cleanInputs = () => {
    let newLoans = [...loans]

    if (!splitLoan[currentIndex]) {
      newLoans = loans.slice(0, 1)
    }

    if (currentIndex in offsetAccounts) {
      properties[currentIndex].offset = cleanValue(offsetAccounts[currentIndex].value)
    } else {
      properties[currentIndex].offset = null
    }

    newLoans.map((l, i) => {
      l.value = cleanValue(l.value)
      l.rate = Number(l.rate) / 100

      if (l.rateType == "FIXED") {
        if (l.knowRevertRate) {
          l.revertRate = Number(l.revertRate) / 100
        } else {
          l.revertRate = rbaRates[properties[currentIndex].purpose]
        }
        l.expiryDate = l.expiryDate.format("YYYY-MM-DD")
      } else {
        l.revertRate = null
      }

      delete l.calendarIsOpen
      delete l.calendarYear
      delete l.expiryDateError
      delete l.knowRevertRateErrorRevertTo
      delete l.rateError
      delete l.revertRateError
      delete l.totalValueError
      delete l.valueError
    })

    return newLoans
  }

  const PropertySummary = ({ index }: { index: number }) => {
    return (
      <Stack direction="row" justifyContent="space-between" alignItems="center" style={{ marginBottom: "20px" }}>
        <Stack spacing={-0.25}>
          <Typography.P comp="medium-bold" fontColor="#202020">
            {properties[currentIndex].address1}
          </Typography.P>
          <Typography.P comp="medium-tight" fontColor="#202020">
            {properties[currentIndex].address2}
          </Typography.P>
        </Stack>
        {properties && <LazyLoadImage src={providerImage(properties[currentIndex].provider.id)} alt={properties[currentIndex].provider.name} title={properties[currentIndex].provider.name} style={{ height: "38px", width: "56px" }} />}
      </Stack>
    )
  }

  return (
    <div className="page-container">
      <div className="survey-container">
        <Typography.H3 comp="hl-survey">Update your home loan</Typography.H3>

        <div style={{ marginTop: "40px" }}>
          <PropertySummary index={currentIndex} />
          <Stack direction="row" alignItems="center" justifyContent="space-between" style={{ height: "60px", marginTop: "40px", marginBottom: "30px" }}>
            <button
              onClick={() => {
                updateSplitLoan(false, currentIndex)
              }}
              style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "48.5%", height: "100%", backgroundColor: !splitLoan[currentIndex] ? "#66CFDC" : "#FFFFFF", color: !splitLoan[currentIndex] ? "#202020" : "#FFFFFF", border: !splitLoan[currentIndex] ? "2px solid #66CFDC" : "2px solid #D5E4E6", borderRadius: "50px", cursor: "pointer" }}
            >
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Typography.P comp={!splitLoan[currentIndex] ? "hl-button-selected" : "hl-button"} fontColor={!splitLoan[currentIndex] ? "#FFFFFF" : "#202020"}>
                  Single Loan
                </Typography.P>
              </Stack>
            </button>
            <button
              onClick={() => {
                updateSplitLoan(true, currentIndex)
              }}
              style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "48.5%", height: "100%", backgroundColor: splitLoan[currentIndex] ? "#66CFDC" : "#FFFFFF", color: splitLoan[currentIndex] ? "#FFFFFF" : "#202020", border: splitLoan[currentIndex] ? "2px solid #66CFDC" : "2px solid #D5E4E6", borderRadius: "50px", cursor: "pointer" }}
            >
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Typography.P comp={splitLoan[currentIndex] ? "hl-button-selected" : "hl-button"} fontColor={splitLoan[currentIndex] ? "#FFFFFF" : "#202020"}>
                  Split Loan
                </Typography.P>
              </Stack>
            </button>
          </Stack>
          {loans.map((l, i) => {
            if (!splitLoan[currentIndex] && i > 0) {
              return
            }
            return (
              <div key={i} style={{ marginBlock: "40px" }}>
                <Stack direction="row" justifyContent="space-between" className={styles.loanHeader}>
                  <Typography.P comp="medium-bold" fontColor="#FFFFFF">
                    {splitLoan[currentIndex] ? "Loan #" + (i + 1) : "Loan"}
                  </Typography.P>
                  <Stack direction="row" alignItems="center">
                    <Typography.P comp="hl-property-small" style={{ paddingRight: i >= 2 ? "0px" : "10px" }}>
                      {properties[currentIndex].address1}
                    </Typography.P>
                    {i >= 2 && <LazyLoadImage src={"/images/icon_remove.png"} style={{ height: "50px", cursor: "pointer", color: "#FCB3CD" }} onClick={() => removeLoan(currentIndex, i)} />}
                  </Stack>
                </Stack>
                <div className={styles.loanBody}>
                  <Typography.P comp="hl-label" style={{ marginBottom: "15px" }}>
                    Current balance
                  </Typography.P>
                  <div style={{ position: "relative", backgroundColor: "#FFFFFF" }}>
                    <Typography.P comp="medium-bold" style={{ position: "absolute", top: "15px", left: "10px" }}>
                      $
                    </Typography.P>
                    <TextField
                      style={{ width: "100%", outline: l.valueError || l.totalValueError ? "3px solid #F9689C" : "none" }}
                      defaultValue={l.value.toLocaleString()}
                      variant="standard"
                      autoComplete="off"
                      InputProps={{
                        disableUnderline: true,
                        style: classes.inputField,
                        // className: styles.inputField,
                      }}
                      inputProps={{
                        inputMode: "decimal",
                        pattern: "[0-9]*",
                      }}
                      onKeyDown={e => {
                        if (e.key === "Enter" || e.key === "Escape") {
                          (e.target as EventTarget & HTMLDivElement).blur()
                        }
                      }}
                      onFocus={e => setTimeout(() => {
                        const l = e.target.value.length
                        const start = e.target.selectionStart || 0
                        const end = Math.max(start, e.target.selectionEnd || 0)
                        const v = Number(e.target.value.replace(/\D+/g, ''))
                        e.target.value = v === 0 ? "": v.toString()
                        e.target.selectionStart = Math.max(0, start - l + e.target.value.length)
                        e.target.selectionEnd = Math.max(0, end - l + e.target.value.length)
                      }, 0)}
                      onChange={(e) => {
                        const l = e.target.value.length
                        const start = e.target.selectionStart || 0
                        const end = Math.max(start, e.target.selectionEnd || 0)
                        const v = Number(e.target.value.replace(/\D+/g, ''))
                        e.target.value = v === 0 ? "": v.toString()
                        e.target.selectionStart = Math.max(0, start - l + e.target.value.length)
                        e.target.selectionEnd = Math.max(0, end - l + e.target.value.length)
                      }}
                      onBlur={e => {
                        formatValue(e)
                        updateLoanValue(e.target.value, currentIndex, i)
                      }}
                    />
                  </div>
                  {l.totalValueError ? (
                    <Typography.P comp="small-bold" fontColor="#F9689C">
                      Total loan can't exceed property value
                    </Typography.P>
                  ) : l.valueError ? (
                    <Typography.P comp="small-bold" fontColor="#F9689C">
                      Loan amount can't be $0
                    </Typography.P>
                  ) : null}

                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography.P comp="hl-label" style={{ marginTop: "20px", marginBottom: "15px" }}>
                      Repaying
                    </Typography.P>
                    <HelpDialog buttonId="repaymentType" icon={"/images/icon_info.png"} info={<div>‘Principal & Interest’ repayments pay off interest and a portion of the loan amount, reducing the balance over time. ‘Interest Only’ repayments cover only the interest charges, leaving the principal untouched. Your bank can confirm the repayment type if you are unsure.</div>} />
                  </Stack>
                  <Stack direction="row" alignItems="center" justifyContent="space-between" style={{ height: "60px" }}>
                    <button onClick={() => updateRepaymentType("PRINCIPAL_AND_INTEREST", currentIndex, i)} style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "49%", height: "100%", backgroundColor: l.repaymentType === "PRINCIPAL_AND_INTEREST" ? "#FFFFFF" : "#EEF4F5", border: "2px solid #FFFFFF", borderRadius: "50px", cursor: "pointer" }}>
                      <Typography.P comp={l.repaymentType === "PRINCIPAL_AND_INTEREST" ? "hl-button-selected" : "hl-button"}>Principal & Interest</Typography.P>
                    </button>
                    <button onClick={() => updateRepaymentType("INTEREST_ONLY", currentIndex, i)} style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "49%", height: "100%", backgroundColor: l.repaymentType === "INTEREST_ONLY" ? "#FFFFFF" : "#EEF4F5", border: "2px solid #FFFFFF", borderRadius: "50px", cursor: "pointer" }}>
                      <Typography.P comp={l.repaymentType === "INTEREST_ONLY" ? "hl-button-selected" : "hl-button"}>Interest only</Typography.P>
                    </button>
                  </Stack>

                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography.P comp="hl-label" style={{ marginTop: "20px", marginBottom: "15px" }}>
                      Current rate
                    </Typography.P>
                    <HelpDialog buttonId="interestRate" icon={"/images/icon_info.png"} info={<div>Your current interest rate should be available within your banking app or on you latest loan statement. Your statement may not reflect the most recent interest rate changes. You can call your bank to confirm.</div>} />
                  </Stack>
                  <div style={{ position: "relative", backgroundColor: "#FFFFFF", outline: l.rate != null && (Number(l.rate) < rateLowerLimit || Number(l.rate) > rateUpperLimit) ? "3px solid #F9689C" : "none" }}>
                    <Typography.P comp="medium-bold" style={{ position: "absolute", top: "15px", right: "15px" }}>
                      %
                    </Typography.P>
                    <TextField
                      style={{ width: "100%", outline: l.rateError ? "3px solid #F9689C" : "none" }}
                      placeholder="0.00"
                      defaultValue={l.rate ? l.rate : ""}
                      variant="standard"
                      autoComplete="off"
                      InputProps={{
                        disableUnderline: true,
                        style: classes.rateInputField,
                        // className: styles.inputField,
                      }}
                      inputProps={{
                        inputMode: "decimal",
                        pattern: "[0-9]*",
                      }}
                      onChange={e => setRateValue(e)}
                      onKeyDown={e => {
                        if (e.key === "Enter" || e.key === "Escape") {
                          (e.target as EventTarget & HTMLDivElement).blur()
                        }
                      }}
                      onBlur={e => {
                        formatRateValue(e, i)
                        updateRateValue(e, currentIndex, i)
                      }}
                    />
                  </div>
                  {l.rate != null && (Number(l.rate) < rateLowerLimit || Number(l.rate) > rateUpperLimit) ? (
                    <Typography.P comp="small-bold" fontColor="#F9689C" style={{ marginTop: "5px" }}>
                      Not a valid home loan rate
                    </Typography.P>
                  ) : null}

                  <Stack direction="row" alignItems="center" justifyContent="space-between" style={{ height: "60px", marginTop: "20px" }}>
                    <button onClick={() => updateRateType("VARIABLE", currentIndex, i)} style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "49%", height: "100%", backgroundColor: l.rateType === "VARIABLE" ? "#FFFFFF" : "#EEF4F5", border: "2px solid #FFFFFF", borderRadius: "50px", cursor: "pointer" }}>
                      <Typography.P comp={l.rateType === "VARIABLE" ? "hl-button-selected" : "hl-button"}>Variable</Typography.P>
                    </button>
                    <button onClick={() => updateRateType("FIXED", currentIndex, i)} style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "49%", height: "100%", backgroundColor: l.rateType === "FIXED" ? "#FFFFFF" : "#EEF4F5", border: "2px solid #FFFFFF", borderRadius: "50px", cursor: "pointer" }}>
                      <Typography.P comp={l.rateType === "FIXED" ? "hl-button-selected" : "hl-button"}>Fixed</Typography.P>
                    </button>
                  </Stack>

                  {l.rateType === "FIXED" && (
                    <div>
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography.P comp="hl-label" style={{ marginTop: "20px", marginBottom: "15px" }}>
                          Fixed rate expiry date
                        </Typography.P>
                        <HelpDialog buttonId="fixedRate" icon={"/images/icon_info.png"} info={<div>Fixed rate loans usually last 1-5 years. To find your fixed rate expiry date, review your online banking app, or most recent statement. Otherwise, simply call your bank and ask.</div>} />
                      </Stack>
                      <div style={{ position: "relative", backgroundColor: "#FFFFFF", width: "100%" }}>
                        <CalendarMonthOutlinedIcon style={{ position: "absolute", top: "12.5px", right: "10px", height: "35px", width: "35px", cursor: "pointer", zIndex: "1" }} onClick={() => updateCalendarIsOpen(!l.calendarIsOpen, currentIndex, i)} />
                        {l.calendarIsOpen && (
                          <div style={{ position: "absolute", top: "0px", left: "0px", width: "320px", backgroundColor: "#FFFFFF", zIndex: "1", border: "1px solid #EEF4F5" }}>
                            <Stack direction="row" justifyContent="space-around" alignItems="center" style={{ height: "60px", borderBottom: "1px solid #EEF4F5" }}>
                              <ChevronLeftIcon style={{ cursor: l.calendarYear > today.getFullYear() ? "pointer" : "default", color: l.calendarYear > today.getFullYear() ? "#000000" : "D2D2D2" }} onClick={() => (l.calendarYear > today.getFullYear() ? updateYear(l.calendarYear - 1, currentIndex, i) : null)} />
                              <Typography.P comp="medium-bold">{l.calendarYear}</Typography.P>
                              <ChevronRightIcon style={{ cursor: "pointer", color: "#000000" }} onClick={() => updateYear(l.calendarYear + 1, currentIndex, i)} />
                            </Stack>
                            {months.map(monthsObj => {
                              return (
                                <Stack direction="row" justifyContent="space-around" alignItems="center" style={{ backgroundColor: "#FFFFFF" }}>
                                  {Object.entries(monthsObj).map(([k, v], j) => {
                                    let className = styles.monthCell
                                    let isDisabled = false
                                    if (l.expiryDate && l.expiryDate["$M"] + 1 == k) {
                                      className = styles.monthCellSelected
                                    } else if (l.calendarYear == today.getFullYear() && Number(k) <= today.getMonth()) {
                                      className = styles.monthCellDisabled
                                      isDisabled = true
                                    }
                                    return (
                                      <Stack justifyContent="center" alignItems="center" className={className} onClick={() => (!isDisabled ? updateExpiryDateFromCalendar(k, currentIndex, i) : null)}>
                                        {v}
                                      </Stack>
                                    )
                                  })}
                                </Stack>
                              )
                            })}
                          </div>
                        )}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            format="MM/YYYY"
                            value={l.expiryDate}
                            onChange={e => {
                              updateExpiryDate(e, currentIndex, i)
                            }}
                            className={styles.datePicker}
                            disableOpenPicker
                            slots={{
                              openPickerIcon: CalendarMonthOutlinedIcon,
                            }}
                            sx={{
                              ".MuiInputBase-input": {
                                width: "100%",
                                fontFamily: "Arboria-Bold, sans-serif",
                                fontSize: "18px",
                                color: l.expiryDate ? "#000000" : "#A9A9A9",
                                border: "none",
                              },
                              "&.MuiTextField-root": {
                                width: "100%",
                                border: "none",
                                outline: l.expirydateError ? "3px solid #F9689C" : "none",
                              },
                              ".MuiOutlinedInput-notchedOutline": {
                                visibility: "hidden",
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </div>

                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography.P comp="hl-label" style={{ marginTop: "20px", marginBottom: "15px" }}>
                          Variable rate after fixed rate expiry
                        </Typography.P>
                        <HelpDialog buttonId="revertRate" icon={"/images/icon_info.png"} info={<div>Once a Fixed rate loan expires, a new interest rate (ie. ‘revert rate’) will apply to the loan. To find the ‘revert rate’, review your online banking app, your most recent loan statement, or alternatively give your bank a call and ask. Otherwise, select ‘I don’t know’ below and we’ll apply an average industry rate from the RBA website.</div>} />
                      </Stack>
                      <Stack direction="row" alignItems="center" justifyContent="space-between" style={{ height: "60px" }}>
                        <button onClick={() => updateKnowRevertRate(true, currentIndex, i)} style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "49%", height: "100%", backgroundColor: l.knowRevertRate ? "#FFFFFF" : "#EEF4F5", border: l.knowRevertRateErrorRevertTo ? "2px solid #F9689C" : "2px solid #FFFFFF", borderRadius: "50px", cursor: "pointer" }}>
                          <Typography.P comp={l.knowRevertRate ? "hl-button-selected" : "hl-button"}>I know it</Typography.P>
                        </button>
                        <button onClick={() => updateKnowRevertRate(false, currentIndex, i)} style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "49%", height: "100%", backgroundColor: !l.knowRevertRate ? "#FFFFFF" : "#EEF4F5", border: "2px solid #FFFFFF", borderRadius: "50px", cursor: "pointer" }}>
                          <Typography.P comp={!l.knowRevertRate ? "hl-button-selected" : "hl-button"}>I don't know it</Typography.P>
                        </button>
                      </Stack>

                      {l.knowRevertRate && (
                        <div>
                          <div style={{ position: "relative", backgroundColor: "#FFFFFF", marginTop: "20px", outline: l.revertRate != null && (Number(l.revertRate) < rateLowerLimit || Number(l.revertRate) > rateUpperLimit) ? "3px solid #F9689C" : "none" }}>
                            <Typography.P comp="medium-bold" style={{ position: "absolute", top: "15px", right: "15px" }}>
                              %
                            </Typography.P>
                            <TextField
                              style={{ width: "100%", outline: l.revertRateError || l.knowRevertRateErrorRevertTo ? "3px solid #F9689C" : "none" }}
                              placeholder="0.00"
                              defaultValue={l.revertRate ? l.revertRate : ""}
                              variant="standard"
                              autoComplete="off"
                              InputProps={{
                                disableUnderline: true,
                                style: classes.rateInputField,
                                // className: styles.inputField,
                              }}
                              inputProps={{
                                inputMode: "decimal",
                                pattern: "[0-9]*",
                              }}
                              onChange={e => setRateValue(e)}
                              onKeyDown={e => {
                                if (e.key === "Enter" || e.key === "Escape") {
                                  (e.target as EventTarget & HTMLDivElement).blur()
                                }
                              }}
                              onBlur={e => {
                                formatRateValue(e, i)
                                updateRevertRateValue(e, currentIndex, i)
                              }}
                            />
                          </div>
                          {l.knowRevertRateErrorRevertTo ? (
                            <Typography.P comp="small-bold" fontColor="#F9689C">
                              {"Use Loan #" + (l.knowRevertRateErrorRevertTo.loanIndex + 1) + " variable rate (" + l.knowRevertRateErrorRevertTo.rate + "%)"}
                            </Typography.P>
                          ) : null}
                          {l.revertRate != null && (Number(l.revertRate) < rateLowerLimit || Number(l.revertRate) > rateUpperLimit) ? (
                            <Typography.P comp="small-bold" fontColor="#F9689C" style={{ marginTop: "5px" }}>
                              Not a valid home loan rate
                            </Typography.P>
                          ) : null}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )
          })}
          {currentIndex in offsetAccounts ? (
            <div>
              <Stack direction="row" justifyContent="space-between" className={styles.loanHeader}>
                <Typography.P comp="medium-bold" fontColor="#FFFFFF">
                  Offset account
                </Typography.P>
                <LazyLoadImage src={"/images/icon_remove.png"} style={{ height: "50px", cursor: "pointer", color: "#FCB3CD" }} onClick={() => removeOffsetAccount(index)} />
              </Stack>
              <div className={styles.loanBody}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography.P comp="hl-label">Current balance</Typography.P>
                  <HelpDialog buttonId="loanValue" icon={"/images/icon_info.png"} info={<div>A mortgage offset account is a linked bank account that reduces the interest on a home loan. The balance in the account is subtracted from the mortgage balance, resulting in lower interest payments. If you have multiple offset accounts, enter the total as the ‘current balance’.</div>} />
                </Stack>
                <div style={{ position: "relative", backgroundColor: "#FFFFFF", marginTop: "15px", outline: offsetAccounts[currentIndex].error ? "3px solid #F9689C" : "none" }}>
                  <Typography.P comp="medium-bold" style={{ position: "absolute", top: "15px", left: "10px" }}>
                    $
                  </Typography.P>
                  <TextField
                    style={{ width: "100%" }}
                    defaultValue={offsetAccounts[currentIndex].value.toLocaleString()}
                    variant="standard"
                    autoComplete="off"
                    InputProps={{
                      disableUnderline: true,
                      style: classes.inputField,
                      // className: styles.inputField,
                    }}
                    inputProps={{
                      inputMode: "decimal",
                      pattern: "[0-9]*",
                    }}
                    onKeyDown={e => {
                      if (e.key === "Enter" || e.key === "Escape") {
                        (e.target as EventTarget & HTMLDivElement).blur()
                      }
                    }}
                    onFocus={e => setTimeout(() => {
                      const l = e.target.value.length
                      const start = e.target.selectionStart || 0
                      const end = Math.max(start, e.target.selectionEnd || 0)
                      const v = Number(e.target.value.replace(/\D+/g, ''))
                      e.target.value = v === 0 ? "": v.toString()
                      e.target.selectionStart = Math.max(0, start - l + e.target.value.length)
                      e.target.selectionEnd = Math.max(0, end - l + e.target.value.length)
                    }, 0)}
                    onChange={(e) => {
                      const l = e.target.value.length
                      const start = e.target.selectionStart || 0
                      const end = Math.max(start, e.target.selectionEnd || 0)
                      const v = Number(e.target.value.replace(/\D+/g, ''))
                      e.target.value = v === 0 ? "": v.toString()
                      e.target.selectionStart = Math.max(0, start - l + e.target.value.length)
                      e.target.selectionEnd = Math.max(0, end - l + e.target.value.length)
                    }}
                    onBlur={e => {
                      formatValue(e)
                      updateOffsetAccounts(e.target.value, false, currentIndex)
                    }}
                  />
                </div>
                {offsetAccounts[currentIndex].error && cleanValue(offsetAccounts[currentIndex].value) > 0 ? (
                  <Typography.P comp="small-bold" fontColor="#F9689C">
                    Offset balance can't exceed loan amount
                  </Typography.P>
                ) : offsetAccounts[currentIndex].error ? (
                  <Typography.P comp="small-bold" fontColor="#F9689C">
                    Offset balance can't be $0
                  </Typography.P>
                ) : null}
              </div>
            </div>
          ) : null}
          {splitLoan[currentIndex] && (
            <Clickable.Text
              comp={3}
              disabled={false}
              className="survey-button"
              style={{ marginBlock: "20px", backgroundColor: "#EEF4F5", color: "#202020" }}
              onClick={() => {
                const newLoans = [...loans]
                newLoans.push(generateLoanObject())
                setLoans(newLoans)
                // DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_LOAN_UPDATED, { "surveyId": state.survey.surveyId, "loans": newLoans })
              }}
            >
              + Add loan
            </Clickable.Text>
          )}
          {!(currentIndex in offsetAccounts) ? (
            <Clickable.Text
              comp={3}
              disabled={false}
              className="survey-button"
              style={{ marginTop: "20px", backgroundColor: "#EEF4F5", color: "#202020" }}
              onClick={() => {
                updateOffsetAccounts("", false, currentIndex)
              }}
            >
              + Add offset account
            </Clickable.Text>
          ) : null}
        </div>
        <Clickable.Text
          comp={3}
          className="hl-survey-button"
          style={{ marginTop: "60px" }}
          onClick={() => {
            if (validateInputs()) {
              const newLoans = cleanInputs()
              properties[currentIndex].loans = newLoans
              properties[currentIndex].timestamp = Math.floor(Date.now() / 1000)
              // DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_PROPERTIES_SUBMITTED, { "surveyId": store.getState().survey.surveyId, ...jsonInput })
              API.updateUserProducts({ properties: properties, updateMode: "OVERWRITE" }, (response: any) => {
                if (response) {
                  navigate("/user/dashboard")
                }
              })
            }
          }}
        >
          Update
        </Clickable.Text>
        <Clickable.Text
          comp={3}
          secondary
          className="hl-survey-button"
          style={{ marginTop: "20px" }}
          onClick={() => {
            navigate("/user/dashboard")
          }}
        >
          Cancel
        </Clickable.Text>
      </div>
    </div>
  )
}

export default UpdateYourHomeLoan
